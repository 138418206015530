var moneyMapZaimy = [1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 11000, 12000, 13000, 14000, 15000, 16000, 17000, 18000, 19000, 20000, 21000, 22000, 23000, 24000, 25000, 26000, 27000, 28000, 29000, 30000, 35000, 40000, 45000, 50000];
var moneyMapAll = [1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 11000, 12000, 13000, 14000, 15000, 16000, 17000, 18000, 19000, 20000, 21000, 22000, 23000, 24000, 25000, 26000, 27000, 28000, 29000, 30000, 35000, 40000, 45000, 50000, 55000, 60000, 65000, 70000, 75000, 80000, 85000, 90000, 95000, 100000, 110000, 120000, 130000, 140000, 150000, 160000, 170000, 180000, 190000, 200000, 210000, 220000, 230000, 240000, 250000, 260000, 270000, 280000, 290000, 300000, 325000, 350000, 375000, 400000, 425000, 450000, 475000, 500000, 525000, 550000, 575000, 600000, 625000, 650000, 675000, 700000, 725000, 750000, 775000, 800000, 1000000, 1500000, 2000000, 2500000, 3000000, 3500000, 4000000, 4500000, 5000000];

$(document).ready(function () {

  var customDays = 61;

  function dateRangeVal(sum) {
      var val = Math.ceil(sum);
      if (val <= 15000) {
        $('#term_amount').val('61 день');
        return 61;
      }
      else if (val >= 15000 && val < 25000) {
        $('#term_amount').val('61 день');
        return 61;
      }
      else if (val >= 25000 && val <= 50000) {
        $('#term_amount').val('61 день');
        return 61;
      }
      else if (val <= 100000) {
        $('#term_amount').val('1 год');
        return 365;
      }
      else if (val <= 1000000) {
        $('#term_amount').val('2 года');
        return 730;
      }
      else if (val <= 5000000) {
        $('#term_amount').val('4 года');
        return 1460;
      }
  }

    var hideBtns = function(val) {
      if (val <= 50000) {
        $('.date_selector_one').text('61 день')
        $('.date_selector_two').hide()
        $('.date_selector_three').hide()
        $('.date_selector_four').hide()
      }  else {
        $('.date_selector_two').show()
        $('.date_selector_three').show()
        $('.date_selector_four').show()
      }
    }

    function setupSlider() {
        var settings = {
            min: 0,
            value: 19,
            max: moneyMapAll.length - 1
        };
        var page = window.location.pathname.split('/')[1];

        if (page === 'zaimy') {
            settings = {
                min: 0,
                value: offerDefaultSummValue,
                max: moneyMapAll.length - 1
            };
        }
        if (page === 'credit') {
            settings = {
                min: 0,
                value: offerDefaultSummValue,
                max: moneyMapAll.length - 1
            };
        }
        if (page === 'cards') {
            settings = {
                min: 0,
                value: offerDefaultSummValue,
                max: moneyMapAll.length - 1
            };
        }
        if (page === 'registration') {
            settings = {
                min: 0,
                value: 9,
                max: moneyMapAll.length - 1
            };
        }

        return settings;
    }

    function numSplitter(get) {
        var numToStr = get.toString();
        return numToStr.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    function setTermDays(one, two, three, four) {
        $('#date_one').data('term-days', one);
        $('#date_two').data('term-days', two);
        $('#date_three').data('term-days', three);
        $('#date_four').data('term-days', four);
    }

    function toPercent(percent, sum, days) {
        return Math.ceil(sum * (percent / 100) * days);
    }

    function setTermSiderVals(sum) {
        var val = Math.ceil(sum);
        if (val <= 15000) {

            // calcData(val, 0.85, 61, '61 день');
            calcData(val, 0.3, 30, '61 день');
            changeDate('1 неделя', '2 недели', '3 недели', '4 недели');
            setTermDays(30, 30, 30, 30);
            hideBtns(val);
        }
        else if (val >= 15000 && val < 25000) {

            // calcData(val, 0.85, 61, '61 день');
            calcData(val, 0.3, 30, '61 день');
            changeDate('1 неделя', '2 недели', '3 недели', '4 недели');
            setTermDays(30, 30, 30, 30);
            hideBtns(val);

        }
        else if (val >= 25000 && val <= 50000) {

            // calcData(val, 0.85, 61, '61 день');
            calcData(val, 0.3, 30, '61 день');
            changeDate('1 неделя', '2 недели', '3 недели', '4 недели');
            setTermDays(30, 30, 30, 30);
            hideBtns(val);

        }
        else if (val <= 100000) {
            hideBtns(val);
            // calcData(val, 0.16, 365, '365 дней');
            calcData(val, 0.06, 365, '365 дней');
            changeDate('6 месяцев', '1 год', '1.5 года', '2 года');
            setTermDays(6*30, 12*30, 18*30, 24*30);

        }
        else if (val <= 1000000) {
            hideBtns(val);
            // calcData(val, 0.03, 730, '2 года');
            calcData(val, 0.02, 730, '2 года');
            changeDate('1 год', '2 года', '3 года', '4 года');
            setTermDays(12*30, 24*30, 36*30, 48*30);

        }
        else if (val <= 5000000) {
            hideBtns(val);
            // calcData(val, 0.03, 1460, '4 года');
            calcData(val, 0.02, 730, '4 года');
            changeDate('2 года', '3 года', '4 года', '5 лет');
            setTermDays(24*30, 36*30, 48*30, 60*30);

        }
    }

    function recalculateFeedVals(sum) {
        $('.feed_item:not(".feed_help")').each(function (i, item) {
            var range = $(item).data('amount-range').split('-');
            if (sum >= parseFloat(range[0]) && sum <= parseFloat(range[1])) {
                $(item).show();

                var $elm = $(item).find('.amount').first();
                $elm.text(sum.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ') + ' ₽');

                var percentage = $(item).data('percentage');
                var days = $('#moneySlider input[name=range]:checked').data('term-days');
                if (typeof days === 'undefined') {
                  var days = 30;
                }
                var totalToReturn = Math.ceil(sum + (sum * (percentage / 100) * days));

                var $rec = $(item).find('.recalculate').first();
                $rec.text(totalToReturn.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ') + ' ₽');
            } else {
                //$(item).hide();
            }

            $('.data-offers-count').html($('.feed_item:not(".feed_help"):visible').length);
        });
    }

    var calcData = function (value, perc, days, daterange) {
        $('.data_comission').text(numSplitter(toPercent(perc, value, days)) + " ₽");
        $('.data_amount').text(numSplitter(value + toPercent(perc, value, days)) + " ₽");
        $('.data_days').text(daterange);
        $('#term').val(days);
    };

    var changeDate = function (first, second, third, fourth) {
        if ($('.date_range')) {
            $('.date_selector_one').text(first);
            $('.date_selector_two').text(second);
            $('.date_selector_three').text(third);
            $('.date_selector_four').text(fourth);
        }
    };

    var sliderSettings = setupSlider();
    $("#slider").slider({
        orientation: "horizontal",
        range: "min",
        animate: true,
        min: sliderSettings.min,
        max: sliderSettings.max,
        value: sliderSettings.value,
        slide: function (event, ui) {
            $("#value").val(numSplitter(moneyMapAll[ui.value]));
            setTermSiderVals(moneyMapAll[ui.value]);
            recalculateFeedVals(moneyMapAll[ui.value]);
            customDays = dateRangeVal(moneyMapAll[ui.value]);
        }
    });

    $("#value").val(numSplitter(moneyMapAll[sliderSettings.value]));

    setTermSiderVals(moneyMapAll[sliderSettings.value]);

    recalculateFeedVals(moneyMapAll[sliderSettings.value]);

    $('#moneySlider input[name=range]').on('change', function () {
        recalculateFeedVals(moneyMapAll[$("#slider").slider("value")]);
    });

    $('.burger').on('click', function () {
        $('.nav-container').toggleClass('is_active');
        $('.media_menu').toggleClass('is_active');
        $('html').toggleClass('active_menu');
    });

    $('.form_input_block').on({
        focus: function () {
            var hintText = $(this).data('hint');
            if (hintText !== "") {
                var parent = $(this).parent('.form_block');
                var hintBlock = '<div class="form_input_hint">' + hintText + '</div>';
                parent.append(hintBlock);
            }
        },

        blur: function () {
            var parent = $(this).parent('.form_block');
            parent.find('.form_input_hint').addClass('slideOutDown').one('webkitAnimationEnd oanimationend msAnimationEnd animationend', function () {
                parent.find('.form_input_hint').remove();
            })
        }

    });
    $("#mainpage_slider").submit(function (e) {
        e.preventDefault();
        localStorage.setItem('loan_term', $('.data_days').text());
        window.location.href = '/vzyat-kredit?amount=' + $("#value").val() + '&term=' + customDays;
    });
    $('.scroll_btn').on('click', function() {
      $('html, body').animate({
                    scrollTop: $(".feed_container").offset().top
                }, 800);
    })
});
!function (a) {
    function f(a, b) {
        if (!(a.originalEvent.touches.length > 1)) {
            a.preventDefault();
            var c = a.originalEvent.changedTouches[0], d = document.createEvent("MouseEvents");
            d.initMouseEvent(b, !0, !0, window, 1, c.screenX, c.screenY, c.clientX, c.clientY, !1, !1, !1, !1, 0, null), a.target.dispatchEvent(d)
        }
    }

    if (a.support.touch = "ontouchend" in document, a.support.touch) {
        var e, b = a.ui.mouse.prototype, c = b._mouseInit, d = b._mouseDestroy;
        b._touchStart = function (a) {
            var b = this;
            !e && b._mouseCapture(a.originalEvent.changedTouches[0]) && (e = !0, b._touchMoved = !1, f(a, "mouseover"), f(a, "mousemove"), f(a, "mousedown"))
        }, b._touchMove = function (a) {
            e && (this._touchMoved = !0, f(a, "mousemove"))
        }, b._touchEnd = function (a) {
            e && (f(a, "mouseup"), f(a, "mouseout"), this._touchMoved || f(a, "click"), e = !1)
        }, b._mouseInit = function () {
            var b = this;
            b.element.bind({
                touchstart: a.proxy(b, "_touchStart"),
                touchmove: a.proxy(b, "_touchMove"),
                touchend: a.proxy(b, "_touchEnd")
            }), c.call(b)
        }, b._mouseDestroy = function () {
            var b = this;
            b.element.unbind({
                touchstart: a.proxy(b, "_touchStart"),
                touchmove: a.proxy(b, "_touchMove"),
                touchend: a.proxy(b, "_touchEnd")
            }), d.call(b)
        }
    }

    $("#last_name").on({
      keydown: function(e) {
        if (e.which === 32)
          return false;
      },
      change: function() {
        this.value = this.value.replace(/\s/g, "");
      }
    });
    $("#name").on({
      keydown: function(e) {
        if (e.which === 32)
          return false;
      },
      change: function() {
        this.value = this.value.replace(/\s/g, "");
      }
    });
    $("#patronymic").on({
      keydown: function(e) {
        if (e.which === 32)
          return false;
      },
      change: function() {
        this.value = this.value.replace(/\s/g, "");
      }
    });


}(jQuery);
